import React, { useEffect, useState } from "react";
import "./slideShow.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const SlideShow = () => {
  const [responsiveItems, setResponsiveItems] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the number of items based on screen width
      if (window.innerWidth < 1000) {
        setResponsiveItems(1); // Set to 1 item for mobile screens
      } else {
        setResponsiveItems(5); // Set to 3 items for larger screens
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(
    () => {
      // You can perform any side effects based on props changes here
      // For example, if props are changing, you can trigger some action here
    },
    [
      /* list of props that you want to watch for changes */
    ]
  );

  const options = {
    loop: true,
    responsiveClass: true,
    center: true,
    items: responsiveItems,
    nav: true,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 6500,
    smartSpeed: 550,
    pagination: false,
    dots: false,
  };

  return (
    <div className="max-width">
      <div className="slideShow">
        <OwlCarousel className="owl-theme" {...options}>
          <div className="item">
            <img src={require("../../img/2x/x1.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/x2.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/x3.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/n1.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/n2.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/n3.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/n4.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/n5.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/n6.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/s1.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/s2.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/s3.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/s4.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c1.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c2.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c3.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c4.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c5.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c6.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c7.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/c8.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/t1.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/t2.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/t3.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/w1.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/w2.avif")} alt="" />
          </div>
          <div className="item">
            <img src={require("../../img/2x/w3.avif")} alt="" />
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default SlideShow;
