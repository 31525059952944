import React, { useState } from "react";
import "./Newsletter.scss";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

const Newsletter = () => {
  const location = useLocation();
  const currentUrl = window.location.href;
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading,setLoading] = useState(false)

  const handleNewsLatter = async (e) => {
    e.preventDefault();
    setLoading(true)
    const data = {
      email,
      currentUrl,
    };
    
    try {
      await axios
        .post(` https://mail-api.notionsgroup.com/api/auth/newslatter`, data)
        .then((response) => {
          setError("Thank you for subscribing! You’re now on our list.");
        });
    } catch (error) {
       setError("Oops! Something went wrong. Please try again.");
    }finally{
      setLoading(false)
    }
    setEmail("")
  };

  return (
    <div className="full_width">
      <div className="news_my_container">
        <div className="newsletter __wrap ">
          <div className="news_info">
            <p>Newsletter</p>
            <h1>
              Subscribe to get <br /> the latest updates
            </h1>
          </div>
          {!error ? (
            <>
              <form onSubmit={handleNewsLatter} className="news_form">
                <div className="news_input">
                  <input
                    type="email"
                    name="Add e mail"
                    id="email"
                    placeholder="example@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {loading ? (
                  <div className="News_button">
                    <button>Sending...</button>
                  </div>
                ) : (
                  <div className="News_button">
                    <button type="submit">Subscribe</button>
                  </div>
                )}
              </form>
            </>
          ) : (
            <>
              <small>{error}</small>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
