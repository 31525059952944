import React from "react";
import "./Copyright.scss";

const Copyright = () => {
  const year = new Date().getFullYear();

  return (
    <div className="copyright">
      <div className="copyright_info">
        <a href="https://redesigno.com/">
          <span style={{ color: "gray" }}>Designed by </span>
        </a>
        <span>Copyright © {year} Notions Group. All Rights Reserved.</span>
      </div>
    </div>
  );
};

export default Copyright;
